/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@msi/cobalt/assets/styles/themes.scss';
@import '@msi/cobalt/assets/styles/variables';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  background-color: var(--background-level-3);
  @include light-theme;
}

body.dark {
  @include dark-theme;
  background-color: var(--background-level-2);
}

body.light {
  @include light-theme;
}

.interactable-field-spacing {
  margin-right: $msi-ui-spacing-xl;
  margin-bottom: $msi-ui-spacing-l;
}

.msi-sidepanel-content .edit-configuration {
  padding-left: 5% !important;
}

.main-modal {
  width: 90% !important;
  height: 75% !important;
}

.msi-sidepanel-header {
  padding-left: unset !important;
}

.msi-sidepanel-modal-header {
  padding-left: 6px !important;
}

.msi-sidepanel-modal {
  width: 90% !important;
  max-height: 85% !important;
}

.msi-sidepanel-modal-overlay {
  justify-content: center !important;
}

.location-provisioning-configuration-page {
  background-color: var(--background-level-2);
  padding: $msi-ui-spacing-s;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .location-provisioning-configuration-menu-container {
    flex: 0;
  }

  .location-provisioning-datatable {
    flex: 1;
  }
}

.location-provisioning-page-header {
  display: flex;
  justify-content: space-between;
}

.location-provisioning-configuration-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.edit-panel-tabs-header {
  width: 100%;
}

.msi-data-table {
  height: unset !important;
  overflow: unset !important;
}

.msi-data-table tbody {
  overflow-x: hidden;
}

.msi-table th .cdk-drag-handle {
  white-space: normal !important;
}

.location-provisioning-datatable {
  width: 100%;
  display: block;
}

.location-provisioning-data-table-row {
  &:hover,
  &:focus {
    background: var(--hover);
    outline: none;
  }

  &.active,
  &:active {
    color: $msi-ui-primary-light !important; // sass-lint:disable-line no-important
    background: var(--focus-primary) !important; // sass-lint:disable-line no-important
  }
}

.msi-bold {
  font-weight: $font-weight-bold;
}

.msi-h4 {
  font-size: $h4-font-size;
}

.top-padding-xsmall {
  padding-top: $msi-ui-spacing-xs;
}

.top-padding-medium {
  padding-top: $msi-ui-spacing-m;
}

.bottom-padding-xsmall {
  padding-bottom: $msi-ui-spacing-xs;
}

.bottom-padding-small {
  padding-bottom: $msi-ui-spacing-s;
}

.padding-xxsmall {
  padding: $msi-ui-spacing-xxs;
}

.left-margin-xxsmall {
  margin-left: $msi-ui-spacing-xxs;
}

.left-margin-xsmall {
  margin-left: $msi-ui-spacing-xs !important;
}

.left-margin-small {
  margin-left: $msi-ui-spacing-s;
}

.left-margin-large {
  margin-left: $msi-ui-spacing-l;
}

.bottom-margin-s {
  margin-bottom: $msi-ui-spacing-s;
}

.bottom-margin-large {
  margin-bottom: $msi-ui-spacing-l;
}

.xsmall-margin {
  margin: $msi-ui-spacing-xs;
}

.msi-body {
  font-size: $msi-ui-primary-font-size;
  font-weight: $font-weight-normal;
  line-height: $default-line-height;
}

.left-padding-large {
  padding-left: $msi-ui-spacing-l;
}

.left-padding-xxlarge {
  padding-left: $msi-ui-spacing-xxl;
}

.input-spacing {
  &:not(:first-child) {
    margin-left: $msi-ui-spacing-s;
  }
  margin-right: $msi-ui-spacing-xs;
  margin-bottom: $msi-ui-spacing-l;
}

.input-spacing-s {
  &:not(:first-child) {
    margin-left: $msi-ui-spacing-xs;
  }
  margin-right: $msi-ui-spacing-xs;
  margin-bottom: $msi-ui-spacing-xs;
}

.surround-spacing-l {
  padding: $msi-ui-spacing-l;
}

.msi-flex-row {
  display: flex;
  flex-direction: row;
}

.medium-input-length {
  min-width: 250px;

  input {
    min-width: 236px;
  }
}

.small-input-length {
  min-width: 120px;

  input {
    min-width: 106px;
    width: 106px !important;
  }
}

msi-sidenav {
  max-width: 260px;
}

msi-select {
  width: 100%;
}

.side-nav-msi-item {
  .msi-item-wrapper {
    width: unset;
    min-width: unset;
  }
}

form {
  width: 100%;
  height: 100%;
}

.save-button {
  margin-left: auto;
}

.back-button {
  margin-top: auto;
  margin-bottom: auto;
}

.error-border {
  border-color: $msi-ui-alert !important;
}

.normal-border {
  border-color: var(--stroke-level-1);
}

.select-background-color {
  background-color: var(--background-level-1) !important;
}

.tool-tip-icon {
  vertical-align: middle;
  color: $msi-ui-primary-dark-40 !important;
}

.msi-select-option .msi-select-option-ellipsis {
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.msi-select-options-list {
  position: unset !important;
}

.msi-table .visible-row {
  &:hover {
    cursor: pointer;
  }
}
